<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';

const store = {
  state: {
    secondSelection: 'progress',
    student: [],
    gradient1: {
      radial: false,
      colors: [
        {
          color: '#03D1AC',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#14D355',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient2: {
      radial: false,
      colors: [
        {
          color: '#1694D0',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#3687FF',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient3: {
      radial: false,
      colors: [
        {
          color: '#CE56EC',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#7B22ED',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient4: {
      radial: false,
      colors: [
        {
          color: '#F03B7C',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#FF3767',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient5: {
      radial: false,
      colors: [
        {
          color: '#FF8743',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#F39C19',
          offset: '100',
          opacity: '1',
        },
      ],
    },
  },
};

export default {
  name: 'ManagementStudentPage',
  data() {
    return {
      store,
      model: {},
    };
  },
  methods: {
    GetStudent() {
      const listStudPath = '/WebListCoursesForStudent.php';
      const data = new FormData();
      data.append('branch_id', this.$route.params.parent_id);
      data.append('student_id', this.$route.params.student_id);
      if (this.model.search !== undefined && this.model.search !== '') {
        data.append('filter', this.model.search);
      }
      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json) {
            store.state.student = json;
          }
        });
    },
  },
  beforeMount() {
    store.state.secondSelection = this.$route.params.selector;
    this.GetStudent();
  },
};
</script>
<template>
  <div class="main-select-students" v-if="store.state.student !== []">
    <div class="main-select-students-1"
         v-if="store.state.secondSelection === 'progress'">
      <b-table-simple class="custom-table">
        <b-thead class="first-thead">
          <b-tr>
            <td style="width: 6%;">
              <div class="back" v-on:click="$router.go(-1)">
                <unicon name="angle-left-b" y="-6"
                        width="18" height="18" fill="#828282"/>
              </div>
            </td>
            <td style="width: 28%;">
              <div class="user-details">
                <div class="d-inline-flex">
                  <div class="user-image">
                    <img :src="store.state.student.image ?
                     store.state.student.image
                    : '/media/user.jpg'" alt=""/>
                  </div>
                  <div class="user-info">
                    <div style="width: 100%">
                      <div class="headline-bold-16"  style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis">
                        <unicon height="24" width="24" name="user-circle" fill="#FDAB3D"
                                style="margin-right: 6px"></unicon>
                        {{ store.state.student.name }}
                      </div>
                      <div class="contains">
<!--                        <div class="ellipse green"></div>-->
                        <div class="caption-2-reg-12 basic-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td  style="width: 60%;" colspan="5">
              <div style="height: 50px;padding: 5px 0;">
              <div class="second-select">
                <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'progress' ? 'active' : '')"
                     v-on:click="store.state.secondSelection = 'progress'">

                  <unicon height="20" width="20" name="chart-line" fill="#1694D0"></unicon>
                  {{ $t("DEPARTAMENTS.PROGRESS") }}
                </div>
                <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'knowledge' ? 'active' : '')"
                     v-on:click="store.state.secondSelection = 'knowledge'">

                  <unicon height="20" width="20" name="chart" fill="#1694D0"></unicon>
                  {{ $t("DEPARTAMENTS.LEARNING_STAGES") }}

                </div>
              </div>
            </div>
            </td>
            <td style="width: 6%;">
              <unicon name="ellipsis-v" fill="#333333"
                      height="16" width="16" style="margin: 16px 0"/>
            </td>
          </b-tr>
        </b-thead>
        <b-thead>
          <b-tr>
            <b-th style="width: 6%;">
              {{ $t("DEPARTAMENTS.NO") }}
            </b-th>
            <b-th style="width: 28%;">
            </b-th>
            <b-th style="width: 10%;">

            </b-th>
            <b-th style="width: 8%;">
              {{ $t("DEPARTAMENTS.RAPIDITY") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.ACCURACY") }}
            </b-th>
            <b-th style="width: 18%;">
              {{ $t("DEPARTAMENTS.MEMORIZATION") }}
            </b-th>
            <b-th style="width: 12%;">
            </b-th>
            <b-th style="width: 6%;">
              <unicon name="setting" fill="#5E5873"/>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="main-select-students-2"
         v-if="store.state.secondSelection === 'knowledge'">
      <b-table-simple class="custom-table">
        <b-thead class="first-thead">
          <b-tr>
            <td style="width: 6%;">
              <div class="back" v-on:click="$router.go(-1)">
                <unicon name="angle-left-b" y="-6"
                        width="18" height="18" fill="#828282"/>
              </div>
            </td>
            <td style="width: 28%;">

              <div class="user-details">
                <div class="d-inline-flex">
                  <div class="user-image">
                    <img :src="store.state.student.image ? store.state.student.image :
                    '/media/user.jpg'" alt=""/>
                  </div>
                  <div class="user-info">
                    <div style="width: 100%">
                      <div class="headline-bold-16"  style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis">
                        <unicon height="24" width="24" name="user-circle" fill="#FDAB3D"
                                style="margin-right: 6px"></unicon>
                        {{ store.state.student.name }}
                      </div>
                      <div class="contains">
<!--                        <div class="ellipse green"></div>-->
                        <div class="caption-2-reg-12 basic-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td  style="width: 60%;" colspan="5">
              <div style="height: 50px;padding: 5px 0;">
                <div class="second-select">
                  <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'progress' ? 'active' : '')"
                       v-on:click="store.state.secondSelection = 'progress'">

                    <unicon height="20" width="20" name="chart-line" fill="#1694D0"></unicon>
                    {{ $t("DEPARTAMENTS.PROGRESS") }}
                  </div>
                  <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'knowledge' ? 'active' : '')"
                       v-on:click="store.state.secondSelection = 'knowledge'">

                    <unicon height="20" width="20" name="chart" fill="#1694D0"></unicon>
                    {{ $t("DEPARTAMENTS.LEARNING_STAGES") }}

                  </div>

                </div>
              </div>
            </td>
            <td style="width: 6%;">
              <unicon name="ellipsis-v" fill="#333333"
                      height="16" width="16" style="margin: 16px 0"/>
            </td>
          </b-tr>
        </b-thead>
        <b-thead>
          <b-tr>
            <b-th style="width: 6%;">
              {{ $t("DEPARTAMENTS.NO") }}
            </b-th>
            <b-th style="width: 28%;">
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.ELEMENTARY") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.MEDIUM") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.EXPERT") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.APPLICATION") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.LIFEHACK") }}
            </b-th>
            <b-th style="width: 6%;">
              <unicon name="setting" fill="#5E5873"/>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>
<style>
.main-select-students .progress{
  height: 9px;
}

.main-select-students .progress.progress-height-32{
  height: 32px;
  border-radius: 8px;
  margin-top: 10px;
}

.main-select-students .progress .bg-primary{
  background-color: #1694D0!important;
}
.main-select-students .progress .bg-secondary{
  background-color: #CDD1FF!important;
}
.main-select-students .progress .bg-success{
  background-color: #00CA72!important;
}
.main-select-students .progress .bg-danger{
  background-color: #E2445C!important;
}
.main-select-students .progress .bg-warning{
  background-color: #FDAB3D!important;
}

.main-select-students .progress-relative{
  position: relative;
}

.main-select-students .progress-absolute{
  position: absolute;
  top: 8px;
  left: 8px;
  color: #00854B;
  font-weight: 600;
  font-size: 14px;
  height: 16px;
}
.main-select-students .progress-absolute svg {
  margin-top: -4px;
}
.legend-custom-style{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.ellipse-progress-green{
  background-color: #f0fffa;
  border-radius: 50%;
}
.ellipse-progress-blue{
  background-color: #f3f6ff;
  border-radius: 50%;
}
.ellipse-progress-purple{
  background-color: #faf4fe;
  border-radius: 50%;
}
.ellipse-progress-red{
  background-color: #fff3f6;
  border-radius: 50%;
}
.ellipse-progress-orange{
  background-color: #fff9f2;
  border-radius: 50%;
}
</style>
